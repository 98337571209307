export function devAisle(st) {
    let arr = [];
    switch (st) {
        case 17:
          arr = [
            { name: '1号 按键', spdId: '', cpKeyValue: 0,disabled:false},
            { name: '2号 按键', spdId: '', cpKeyValue: 1,disabled:false},
            { name: '3号 按键', spdId: '', cpKeyValue: 2,disabled:false},
            { name: '4号 按键', spdId: '', cpKeyValue: 3,disabled:false},
          ];
          break;
        case 38:
        case 115:
        case 18:
          arr = [
            { name: '插卡取电', spdId: '', cpKeyValue: 0,disabled:false },
            { name: '拔卡断电', spdId: '', cpKeyValue: 1,disabled:false },
          ];
          break;
        case 22:
        case 145:
          arr = [
            { name: '1号 按键', spdId: '', cpKeyValue: 0,disabled:false },
            { name: '2号 按键', spdId: '', cpKeyValue: 1,disabled:false },
          ];
          break;
        case 28:
          arr = [
            { name: '我回家了', spdId: '', cpKeyValue: 0,disabled:false },
            { name: '我出门了', spdId: '', cpKeyValue: 1,disabled:false },
            { name: '就餐模式', spdId: '', cpKeyValue: 2,disabled:false },
            { name: '影音模式', spdId: '', cpKeyValue: 3,disabled:false },
            { name: '会客模式', spdId: '', cpKeyValue: 4,disabled:false },
            { name: '我要睡了', spdId: '', cpKeyValue: 5,disabled:false },
            { name: '起床模式', spdId: '', cpKeyValue: 6,disabled:false },
            { name: '我要看书', spdId: '', cpKeyValue: 7,disabled:false },
            { name: '吃 饭 了', spdId: '', cpKeyValue: 8,disabled:false },
            { name: '休闲模式', spdId: '', cpKeyValue: 9,disabled:false },
            { name: ' 晚 安 ', spdId: '', cpKeyValue: 10,disabled:false },
          ];
          break;
        case 58:
        case 60:
          arr = [{ name: '开锁联动', spdId: '', cpKeyValue: 0,disabled:false }];
          break;
        case 161:
          arr = [
            { name: '1号 按键', spdId: '', cpKeyValue: 1,disabled:false},
            { name: '2号 按键', spdId: '', cpKeyValue: 2,disabled:false},
            { name: '3号 按键', spdId: '', cpKeyValue: 3,disabled:false},
            { name: '4号 按键', spdId: '', cpKeyValue: 4,disabled:false},
            { name: '5号 按键', spdId: '', cpKeyValue: 5,disabled:false},
            { name: '6号 按键', spdId: '', cpKeyValue: 6,disabled:false},
            { name: '7号 按键', spdId: '', cpKeyValue: 7,disabled:false},
            { name: '8号 按键', spdId: '', cpKeyValue: 8,disabled:false},
          ];
          break;
        case 129:
          arr = [
            { name: '我回家了', spdId: '', cpKeyValue: 1,disabled:false },
            { name: '我出门了', spdId: '', cpKeyValue: 0,disabled:false },
            { name: '睡眠模式', spdId: '', cpKeyValue: 2,disabled:false },
            { name: '休闲模式', spdId: '', cpKeyValue: 3,disabled:false },
            { name: '会客模式', spdId: '', cpKeyValue: 4,disabled:false },
            { name: '影院模式', spdId: '', cpKeyValue: 5,disabled:false },
            { name: '就餐模式', spdId: '', cpKeyValue: 6,disabled:false },
          ];
          break;
      case 31:
        arr = [
          { name: '开', spdId: '', cpKeyValue: 1,disabled:false },
          { name: '关', spdId: '', cpKeyValue: 0,disabled:false },

        ]
        default:
          break;
    }
    return arr
}

export function hasHs(hsId) {
    if (hsId) {
        return true
    } else {
        return false
    }
}

    //深拷贝
export function deepClone (obj = {}){
  if(typeof obj !== 'object' || obj ==null){
    return obj;
  }
  let result;
  if(obj instanceof Array){
    result = [];
  } else {
    result = {};
  }
  for(let key in obj ){
    if(obj.hasOwnProperty(key)){
      result[key] = deepClone(obj[key]);
    }
  }
  return result
}

export function findElem(arrayToSearch, attr, val) {
  for (var i = 0; i < arrayToSearch.length; i++) {
       if (arrayToSearch[i][attr] == val) {
           return i;
       }
   }
   return -1;
}

export function tiemSplit(tiem){
  if(tiem){
    let arr = tiem.split(':');
    let str = `${arr[0]}:${arr[1]}:00`
    return str
  }else{
    return undefined
  }
}