
<template>
  <div>
      <el-row class="control">
          <el-col :span="23" :push="1">
            控制后延时：<el-input-number
              style="width: 100px"
              v-model="controlObj.delayTime"
              controls-position="right"
              :min="0"
              :max="15"
            ></el-input-number
            >&nbsp;秒后再执行。（0为不延时，1-15秒为时间）
          </el-col>
        </el-row>
        <el-row class="control">
          <el-col :span="23" :push="1">
            执行后延时：<el-input-number
              style="width: 100px"
              v-model="controlObj.actionTime"
              controls-position="right"
              :min="0"
              :max="15"
            ></el-input-number
            >&nbsp;秒后自动恢复。（0为不延时，1-15秒为时间）
          </el-col>
        </el-row>
        <el-row class="hint">
          <el-col
            :span="23"
            :push="1"
            v-if="controlObj.delayTime && controlObj.actionTime"
          >
            设置示例：控制成功时，延时{{
              controlObj.delayTime
            }}秒钟后执行开，执行成功{{ controlObj.actionTime }}秒钟后执行关。
          </el-col>
          <el-col :span="23" :push="1" v-else-if="controlObj.delayTime">
            设置示例：控制成功时，延时{{ controlObj.delayTime }}秒钟后执行开。
          </el-col>
          <el-col :span="23" :push="1" v-else-if="controlObj.actionTime">
            设置示例：控制成功时，执行成功{{
              controlObj.actionTime
            }}秒钟后执行关。
          </el-col>
          <el-col align="center" :span="24" v-else> 立即执行 </el-col>
        </el-row>
        <el-row v-if="controlBoxOpen?true:false">
            <el-col class=""  :span="24" align="right">
                <el-button type="primary" size="minis" class="devButGreen" @click="controlBoxOpen">保存</el-button>
            </el-col>
        </el-row>
  </div>
</template>

<script>
export default {
    name:'controlBoxSetUp',
    props:{
        controlObj:{
            type:Object
        },
        controlBoxOpen:{
            type:Function
        },
        id:Number|String
    },
    components: {
    },
  data() {
    return {

    }
  },
  created(){
    },
  methods:{
  }
};
</script>
<style>
</style>
<style lang="scss" scoped>
@import '../../devButStyle.scss';
.controlObjSttingBox /deep/ .el-dialog__header{
    background-color:#ECECEC;
}
.controlObjSttingBox /deep/ .el-dialog__body{
    background-color:#ECECEC;
}
.controlObjSttingBox /deep/.el-dialog__title{
    font-size:18px;
    color:#9B9C9D;
}
.controlObjSttingBox /deep/.el-input__inner{
    font-size:20px;
    color:#9B9C9D;
}
.controlObjSttingBox{
    .control{
        color:#9B9C9D;
    }
    .el-row{
        font-weight:bold;
        margin-bottom: 15px;
    }
    .hint{
        color:#7ed321;
    }

}
</style>
