<template>
  <div>
      <el-row style="padding: 5px 5px 5px 0" class="text-weight">
        安装地址：<el-input size="mini" v-model="form.params.room" @click.native="seleceRoom" readonly style="width: 15%" ></el-input>
        <el-dialog v-dialogDrag title="选择其他房间" :visible.sync="installationAddress" :close-on-click-modal="false" @close="installationAddress = false" width="800px" append-to-body>
            <InstallationAddress ref="installationAddress" v-if="installationAddress" :hsIds="this.form.params.hsIds" :isDuoXuan="isDuoXuan=true"/>
            <div slot="footer">
                <el-button @click="installationAddress = false">取 消</el-button>
                <el-button type="primary" @click="confirmSelece">搜 索</el-button>
            </div>
        </el-dialog>
        设备名称：<el-input size="mini" v-model="form.params.devNickname" @change="nameChange" clearable style="width: 15%"></el-input>
        所属网关：<el-input size="mini" v-model="form.params.devAuthSecret" @change="devAuthSecretChange" clearable style="width: 15%"></el-input>
        设备SN：<el-input size="mini" v-model="form.params.devSn" @change="devSnChange" clearable style="width: 15%"></el-input>
      </el-row>
      <el-table ref="multipleTable" v-loading="standSceneListData.loading" :data="standSceneListData.sceneList" tooltip-effect="dark" style="width: 100%" height="400" highlight-current-row @select="selectRow"  @selection-change="allSelection" @row-dblclick="dbPitchOn">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="devNickname" label="设备名称" align="center" show-overflow-tooltip ></el-table-column>
        <el-table-column prop="devSn" label="设备SN" align="center" show-overflow-tooltip ></el-table-column>
        <el-table-column prop="devAuthSecret" label="设备网关" align="center" show-overflow-tooltip ></el-table-column>
        <el-table-column prop="status" label="添加状态" align="center" :filters="[ { text: '已添加', value: '已添加' }, { text: '未添加', value: '未添加' }, ]" :filter-method="filterTag" filter-placement="bottom-end">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == '已添加' ? 'primary' : 'success'" disable-transitions >{{ scope.row.status }}</el-tag >
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top:10px;text-align:right">
        <el-button @click="standSceneListData.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addDev">添 加</el-button>
      </div>
  </div>
</template>
<script>
import { equipmentSecondLevel,statusInformationId,standSceneList,linkageGetLinkageDevice } from '@/api/scene';
import InstallationAddress from '@/components/equipmentChild/installationAddress.vue'
import { mapActions, mapState } from 'vuex'
export default {
  name:"addDeviceIntoList",
  props: {
    standSceneListData: Object,
    test:{
      default:'',
      type:String
    },
    selectType:{
      default:null,
      type:Number,
    },
  },
  components:{
    InstallationAddress
  },
  computed:{
    // ...mapState({
    //   equipmenData:state => state.goUseVuex.equipmenData,
    //   equipmenContent:state => state.contextualModelState.locationDeviceList,
    // })
    ...mapState(['setEquipmenData','setEqContent'])
  },
  data() {
    return {
      aaa:[
        {c:49},
      ],
      setRrray:[],
      setHello:[],
      setSptData:[],
      setItem:"",
      setAbc:[],
      dstDfmId:"", 
      setEquipmentStatus:"",
      installationAddress:false,
      selectedDev: [],
      selectedData:[], // 添加设备触发 单选
      setDatas:[],
      form: {
        //设备列表数据
        params: {
          devNickname: '',
          devSn: '',
          devAuthSecret: '',
          hsIds:[],
          status: '',
          room:'选择了1个房间',
        },
      },

    };
  },
  watch: {
    'standSceneListData.sceneList': function() {
      this.$nextTick(function() {
        this.detectionRedDev();
      });
    },
    'standSceneListData.dialogVisible': function() {
      if (this.standSceneListData.dialogVisible) {
        this.$nextTick(function() {
          this.$refs.multipleTable.clearSelection();
          this.detectionRedDev();
        });
      }
    },
    'form.params.hsIds':{
      handler(newName, oldName) {
        console.log(newName , oldName);
        if(newName.length == oldName.length){return}
        this.form.params.room = `${'选择了'+newName.length + '个房间'}`
      },
    }
  },
  created(){
    this.form.params.hsIds = [this.standSceneListData.hsId];
    // ----------------------------------------------------
    // equipmentSecondLevel(3,6).then(res=>{
    //   console.log(res.data.result,'666')
    // }).catch(err=>{
    //   console.log(err,'err')
    // })
    // statusInformationId(1,2).then(res=>{
    //   console.log(res.data.result,' 999')
    // }).catch(err=>{
    //   console.log(err,'err')
    // })
    // ----------------------------------------------------
  },
  methods: {
    ...mapActions(['getEquipment','getStatusBus']),
    // -----------------------------------------------------
    confirmSelece(){
      this.installationAddress = false;

      this.form.params.hsIds = [this.standSceneListData.hsId]
      this.$refs.installationAddress.multipleSelection.forEach(item => {
        this.form.params.hsIds.push(item.hsId);
      });

      this.form.params.hsIds = Array.from(new Set(this.form.params.hsIds))
      this.getStandSceneList()
    },
    seleceRoom(){
      if(this.form.params.hsIds.length < 1){
        this.form.params.hsIds = [this.standSceneListData.hsId]
      }
      this.installationAddress = true;
    },
    filterTag(value, row) {
      return row.status == value;
    },
    dbPitchOn(row, column, event) {
      this.$refs.multipleTable.toggleRowSelection(row);
      
    },
    allSelection(val) {
      this.selectedDev = val;
      // this.selectedData = val;
      // this.selectedData.map(e=>{
      //    this.selectedData =e.devFirstType
      // })
      // this.setDatas = this.selectedData.toString()
      // console.log(this.setDatas)
      // ---------------------------------
      // equipmentSecondLevel(this.devFirstType?this.devFirstType:"").then(res=>{
      // console.log(res,'res 66666')
      // let {dstDfmId} =res.data.result[0]
      // dstDfmId =dstDfmId.split(',',1)
      // this.dstDfmId=dstDfmId[0]
      // }).catch(err=>{
      // console.log(err,'err 陈')
      // })

      // --------------------------------
      // let bbb =this.aaa.map(item=>item.c)
      // console.log(bbb)
      // this.getEquipment(bbb)
      // this.getStatusBus(bbb)
      // --------------------------------

      
    },
    selectRow(selection){
      console.log(selection,'selection')
      this.selectedData =selection.map(item => item.deviceFunctionState)
      console.log(this.selectedData)
    },
    addDev() {
      this.$emit('getDeviceHello',{Dev:this.selectedData})

      // let msg=this.selectedData.map(item =>item.devSecondType)
      // await this.getEquipment(msg)
      // console.log(JSON.parse(JSON.stringify(this.setEquipmenData)));
      // this.setAbc = [];
      // this.setEquipmenData.map(item=>{
      //   if(item.dstDfmId == undefined || item.dstDfmId == ""){
      //     return
      //   }
      //   else{
      //     let letDset = item.dstDfmId
      //     this.setAbc = [...this.setAbc,letDset]
      //   } 
      // })
      // let arrayTemp=[]
      // let setHei = this.setAbc.map(item=>{
      //   let setItem = item
      //   let setSpts = setItem.split(",")
      //   return setSpts
      // })
      // setHei.map(item=>{
      //   arrayTemp=[...arrayTemp,...item]
      // })
      // console.log(setHei,arrayTemp)
      // await this.getStatusBus(arrayTemp)
      //  console.log(arrayTemp)
      //  console.log(JSON.parse(JSON.stringify(this.setEquipmenData)));

      // console.log(this.setEqContent,'setEqContent')
      // for(var i in setHei){

      //   console.log(setHei)
      //   this.setRrray.push(setHei[i])
      // }
      // console.log(this.setRrray,'setRrray')
    
      // if({...this.setSptData[0].length}>1){
      //    console.log('111')
      //    this.getStatusBus(...this.setSptData)
      // }else{
      //   console.log('222')
      // }
      // console.log('111')
      // this.getStatusBus(...this.setSptData) 
    
      //  let setAaa= this.setAbc.split(",")
        // this.setAbc.map(item=>{
        //   this.setItem =item
        // })
        // let setAaa =this.setItem.split(",")
        // console.log(setAaa)
      // this.getStatusBus(abc) 
      // let bbb =this.aaa.map(item=>item.c)
      // this.getStatusBus(bbb) 

      // console.log(this.setEquipmenData,'setEquipmenData')

      // 47,
      // 49,56,57,62
      // 18,19,20,23,67

      // ----------------------------------------
      // abc = abc.toString()
      // let ccc = abc.split(',',1)
      // console.log(ccc)
      // this.getStatusBus()
      // console.log(this.setEqContent,'setEqContent')
      // let {dstDfmId} =this.setEquipmenData[0]
      // dstDfmId =dstDfmId?dstDfmId.split(',',1):""
      // this.dstDfmId=dstDfmId[0]
      // console.log(this.dstDfmId,'dstDfmId')
      // console.log(this.setEqContent,'setEqContent')
      // 47,
      // 49,56,57,62
      // 18,19,20,23,67
      // console.log(this.setEqContent,'setEqContent')
      // ---------------------------------------------------
      // console.log(this.setEquipmenData,'setEquipmenData')
      // ----------------------------
      this.standSceneListData.dialogVisible = false;
      // console.log("selectedDev:",this.selectedDev);
      this.$emit('func', this.selectedDev);
      // this.$emit('setDevice',this.setEqContent)
      // -----------------------------------
      // statusInformationId(this.dstDfmId?this.dstDfmId:"").then(res=>{
      // // console.log(res,' res 萍柳吖')
      // this.setEquipmentStatus=res.data.result
      // console.log(this.setEquipmentStatus,'989898')
      // }).catch(err=>{
      // console.log(err,'err 萍柳吖')
      // })
      // this.$emit('setEquipmentStatus',this.setEquipmentStatus)
    },
    detectionRedDev() {
      let arr1 = this.standSceneListData.devTable;
      let arr2 = this.standSceneListData.sceneList;
      for (let i = 0; i < arr2.length; i++) {
        this.$set(arr2[i],'status','未添加')
        // arr2[i].status = '未添加';
        for (let j = 0; j < arr1.length; j++) {
          if (arr1[j].jourDeviceId == arr2[i].jourDeviceId) {
            this.$refs.multipleTable.toggleRowSelection(arr2[i]);
            this.$set(arr2[i],'status','已添加')
            // arr2[i].status = '已添加';
          }
        }
      }
    },
    nameChange() {
      //设备名称查询
      this.getStandSceneList();
    },
    devAuthSecretChange() {
      //设备网关查询
      this.getStandSceneList();
    },
    devSnChange() {
      //设备SN查询
      this.getStandSceneList();
    },
    getStandSceneList() {
      this.standSceneListData.loading = true;
      // this.$emit('func', this.selectedDev);
      if(this.form.params.hsIds<1){
        this.form.params.hsIds = [this.standSceneListData.hsId]
      }
      console.log("test",this.test);
      if(this.test == '触发设备'){
        linkageGetLinkageDevice({
            // devNickname: '',
            // devSn: '',
            // devAuthSecret: '',
            // hsIds:this.form.params.hsIds,
            // selectType:this.selectType
            devNickname: this.form.params.devNickname,
            devSn: this.form.params.devSn,
            devAuthSecret: this.form.params.devAuthSecret,
            hsIds:this.form.params.hsIds,
            selectType:this.selectType,
            selectAll:1
            })
        .then((res) => {
            console.log(res,'9898989898')
            this.standSceneListData.sceneList = res.data.result.filter(e=>e.deviceFunctionState);
            this.standSceneListData.loading = false;
        })
      }else if(this.test == '执行设备'){
        standSceneList({
          devNickname: this.form.params.devNickname,
          devSn: this.form.params.devSn,
          devAuthSecret: this.form.params.devAuthSecret,
          hsIds:this.form.params.hsIds
        }).then((res) => {
          console.log(res,'8787878787')
          this.standSceneListData.sceneList = res.data.result.filter(e=>e.deviceFunctionState);
          this.standSceneListData.loading = false;
          console.log('sceneList', this.standSceneListData.sceneList);
        }).catch((err) => {
          this.standSceneListData.loading = false;
          this.isError(err, this);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
