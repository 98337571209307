<template>
  <div>
    <el-form :model="selCity" :inline="true">
      <el-form-item>
        房屋类型：<el-select v-model="selCity.params.hsAddCity" @change="hsAddCityChange" size="mini" style="width:150px" clearable>
        <el-option label="房间列表" value=""></el-option>
        <el-option label="公区" value="公区"></el-option>
        <el-option label="办公室" value="办公室"></el-option>
      </el-select>&nbsp;&nbsp;
        房屋名称：<el-input @change="hsAddCommunityChange" style="width:150px" size="mini" v-model="selCity.params.hsAddCommunity" clearable></el-input>&nbsp;&nbsp;
      </el-form-item>
      <el-form-item><el-button type="primary" @click="closeSelece" size="mini" v-if="isDuoXuan">重新选择</el-button></el-form-item>
    </el-form>
    <!--数据表-->
    <el-table  @row-dblclick="bangDingRowDBClick" ref="tableRef" v-loading="loading" :data="selCity.houseList" stripe style="width:100%;" highlight-current-row @row-click="bangDingRowClick" @selection-change="handleSelectionChange" :row-key="bindRowKeys" height="55vh">
      <el-table-column type="selection" width="55" v-if="isDuoXuan" :reserve-selection="true"></el-table-column>
      <el-table-column prop="hsAddCity" label="房屋类型" min-width="80px"></el-table-column>
      <el-table-column label="房屋地址" min-width="100px">
        <template slot-scope="scope">
          <div>
            {{ scope.row.hsAddCommunity }} {{ scope.row.hsAddFloor }} {{ scope.row.hsAddDoorplateno }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total, sizes, prev, pager, next, jumper"
      :total="selCity.total"
      :page-count="selCity.pages"
      :page-size="selCity.limit"
      :page-sizes="[5, 10, 20, 40, 100]"
      :current-page="selCity.cursor"
      @size-change="sizeChange"
      @current-change="chengquChange"
      align="center"
      background>
    </el-pagination>
  </div>
</template>
<script>
  export default {
    props:{
      isDuoXuan:{
        default:false,
        type:Boolean,
      },
      hsIds:Array,
    },
    data(){
      return {
        loading:false,
        multipleSelection:[],
        selCity:{//绑定设备中房屋数据
          params:{
            hsAddCity:'',
            hsAddCommunity:'',
          },
          bdclickrow:'',
          houseList:[],
          cursor: 1,  //当前页面
          limit: 10,  //每页个数
          total: 0,         //总条数
          pages: 0,      //总页数
        },
      }
    },
    created(){
      this.getHouse()
    },
    methods: {
      // test(row){
      //   console.log("test:",row)
      // },
      //选择其他房间-双击事件
      bangDingRowDBClick(row){
        this.selCity.bdclickrow=row
        this.$emit('rowDB',this.selCity.bdclickrow)
      },
      closeSelece(){
        this.$refs.tableRef.clearSelection();
      },
      openLoading(){
        this.loading = true
      },
      closeLoading(){
        this.loading = false
      },
      bindRowKeys(row){
        return row.hsId
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      getHouse(){ //获取所有房子
      this.openLoading()
        this.$axios({
          method:"get",
          url: '/common1/house4store',
          params:{
            limit:this.selCity.limit,
            cursor:this.selCity.cursor,
            hsAddCity:this.selCity.params.hsAddCity,
            houseAddress:this.selCity.params.hsAddCommunity,
            hsState: "正常"
          }
        }).then(res=>{
          this.selCity.houseList=res.data.result.records
          this.selCity.total = res
          .data.result.total
          this.closeLoading()
          console.log(this.hsIds)
          if(this.isDuoXuan){
            this.selCity.houseList.forEach(item =>{
              this.hsIds.f
              orEach(key =>{
                if(item.hsId == key){
                  this.$refs.tableRef.toggleRowSelection(item);
                };
              })
            })
          }
        }).catch(err=>{
          this.closeLoading()
          this.isError(err,this)
        })
      },
      chengquChange(cursor){//房子分页
        this.selCity.cursor=cursor
        this.getHouse()

      },
      sizeChange(size) { //页面显示条数改变
          this.selCity.limit = size
          this.getHouse()
      },
      hsAddCityChange(){//房屋类型查询
        this.selCity.cursor=1
        this.getHouse()
      },
      hsAddCommunityChange(){//楼盘查询
        this.selCity.cursor=1
        this.getHouse()
      },
      bangDingRowClick(row){//绑定设备选中一行
        this.selCity.bdclickrow=row
        this.$emit('func',this.selCity.bdclickrow)
      },
    }
  }
</script>
