<template>
  <div>
      <el-form v-if="coolingAndHeatingLamp.secondType.indexOf(panelForm.devSecondType) > -1">
        <el-form-item label="亮度：" label-width="50px">
                <el-slider
                  v-model.number="coolingAndHeatingLamp.mode.brightness"
                  :min="1"
                  :max="100"
                  :marks="{25:'25', 50: '50', 75: '75'}"
                ></el-slider>
        </el-form-item>
        <el-form-item label="色温："  label-width="50px">
              <el-slider
                v-model.number="coolingAndHeatingLamp.mode.colorTemperature"
                :min="1"
                :max="100"
                :marks="{25:'25 ', 50: '50', 75: '75'}"
              ></el-slider>
        </el-form-item>
      </el-form>
      <el-form v-else-if="airConditioner.secondType.indexOf(panelForm.devSecondType) > -1">
        <el-form-item>
          <el-col :span="3">温度：</el-col>
          <el-col :span="21">
            <el-input
              v-if="panelFormRow.devSecondType != 141"
              v-model="airConditioner.mode.temperature"
              clearable
              size="small"
              placeholder="请输入15到35之间的数字"
            ></el-input>
            <el-input
            v-else
              v-model="airConditioner.mode.temperature"
              clearable
              size="small"
              placeholder="请输入15到30之间的数字"
            ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-col :span="3">模式：</el-col>
          <el-col :span="21">
            <el-select
              v-model="airConditioner.mode.pattern"
              clearable
              size="small"
              style="width: 100%"
            >
              <el-option label="自动" value="自动" v-if="panelFormRow.devSecondType != 49 && panelFormRow.devSecondType != 137 && panelFormRow.devSecondType != 141"></el-option>
              <el-option label="制冷" value="制冷"></el-option>
              <el-option label="制热" value="制热"></el-option>
              <el-option label="送风" value="送风"></el-option>
              <el-option label="除湿" value="除湿" v-if="panelFormRow.devSecondType != 49 && panelFormRow.devSecondType != 137"></el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-col :span="3">风速：</el-col>
          <el-col :span="21">
            <el-select
              v-model="airConditioner.mode.windSpeed"
              clearable
              size="small"
              style="width: 100%"
            >
              <el-option label="自动" value="自动" v-if="panelFormRow.devSecondType != 141"></el-option>
              <el-option label="高" value="高"></el-option>
              <el-option label="中" value="中"></el-option>
              <el-option label="低" value="低"></el-option>
            </el-select>
          </el-col>
        </el-form-item>
      </el-form>
      <el-form v-else-if="controlBox.secondType.indexOf(panelForm.devSecondType) > -1" class="controlObjSttingBox" >
          <ControlBoxSetUp ref="ControlBoxSetUp" :controlObj="controlBox.mode"></ControlBoxSetUp>
      </el-form>
      <el-form v-else>
        <el-row>
          <el-col align="center">
            没有相关操作
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" style="text-align: right;">
        <el-button v-if="coolingAndHeatingLamp.secondType.indexOf(panelForm.devSecondType) > -1||
                        airConditioner.secondType.indexOf(panelForm.devSecondType) > -1||
                        controlBox.secondType.indexOf(panelForm.devSecondType) > -1" 
                        type="primary" @click="assignmentDetailedSettings">确定</el-button>
      </div>
  </div>
</template>

<script>
import ControlBoxSetUp from '@/components/EquipmentTable/components/controlBox/controlBoxSetUp/controlBoxSetUp.vue'

export default {
  props: {
    isDevSecondType: {
      type: String | Number,
      required: '',
    },
    isDevFirstType: {
      type: String | Number,
      required: '',
    },
    panelFormRow: {
      type: Object,
      default(){
        return {}
      },
    },
    id:String|Number
  },
  components:{
    ControlBoxSetUp
  },
  created() {
    console.log(this.panelFormRow)
  },
  data() {
    return {
      panelForm: {
        //操作相关
        devSecondType: this.isDevSecondType,
        devFirstType: this.isDevFirstType,
      },
      airConditioner: {
        //空调
        secondType: [4, 42, 43, 49,137,141],
        mode: {
          temperature: this.panelFormRow.temperature,
          pattern: this.panelFormRow.pattern,
          windSpeed: this.panelFormRow.windSpeed,
        },
      },
      coolingAndHeatingLamp: {
        //冷暖灯
        secondType: [31, 44, 56, 76, 97,98,99],
        mode: {
          brightness: this.panelFormRow.brightness || 50,
          colorTemperature: this.panelFormRow.colorTemperature || 50,
        },
      },
      adjustingLamp: {
        //调节灯
        secondType: [36, 59],
        mode: {
          brightness: this.panelFormRow.brightness,
        },
      },
      underfloorHeatingLamp: {
        //地暖
        secondType: [57],
        mode: {
          brightness: this.panelFormRow.brightness,
        },
      },

      lnfraredAlarm: {
        // 红外
        secondType: [93, 95, 106],
        mode: {
          devId: this.panelFormRow.devId,
          switchs: this.panelFormRow.switchs,
          spacing: this.panelFormRow.spacing,
          threshold: this.panelFormRow.threshold,
          sensitivity: this.panelFormRow.sensitivity,
          periodVaule: this.panelFormRow.periodVaule,
          thresholdVaule: this.panelFormRow.thresholdVaule,
          sensitivityVaule: this.panelFormRow.sensitivityVaule,
        },
      },
      //窗帘
      percentageCurtain: {
        secondType: [117, 108],
        mode: {
          percentCurtain: this.panelFormRow.percentCurtain,
        },
      },
      //单路隔离控制盒
      controlBox:{
        secondType:[144],
        mode: {
            delayTime:this.panelFormRow.delayTime || 0,
            actionTime:this.panelFormRow.actionTime || 0,
        },
      },
    };
  },
  watch: {},
  methods: {
    assignmentDetailedSettings() {
      let devSecondType = this.isDevSecondType;
      console.log(devSecondType)
      let arr = {};
      if (this.airConditioner.secondType.indexOf(devSecondType) > -1) {
        arr = this.airConditioner.mode;
      } else if (
        this.coolingAndHeatingLamp.secondType.indexOf(devSecondType) > -1
      ) {
        arr = this.coolingAndHeatingLamp.mode;
      } else if (this.adjustingLamp.secondType.indexOf(devSecondType) > -1) {
        arr = this.adjustingLamp.mode;
      } else if (this.lnfraredAlarm.secondType.indexOf(devSecondType) > -1) {
        arr = this.lnfraredAlarm.mode;
        for (let i of this.scenarioSetting.mode.deviceList) {
          if (i.devSn == this.scenarioSetting.mode.row.devSn) {
            console.log(i.jsroImsState);
            console.log(arr);
            i.jsroImsState.periodVaule = arr.periodVaule;
            i.jsroImsState.sensitivity = arr.sensitivity;
            i.jsroImsState.spacing = arr.spacing;
            i.jsroImsState.threshold = arr.threshold;
            i.jsroImsState.thresholdVaule = arr.thresholdVaule;
            i.jsroImsState.switchs = arr.switchs;
            if (arr.switchs == 1) {
              i.jsroImsState.state = '开';
            } else if (arr.switchs == 2) {
              i.jsroImsState.state = '关';
            }
          }
        }
      }else if (this.controlBox.secondType.indexOf(devSecondType) > -1) {
        arr = this.controlBox.mode;
      }
      console.log(arr)
      this.$emit('closeEquipmentList', arr);
    },
  },
};
</script>
<style lang="scss">
.controlObjSttingBox{
    .control{
        color:#9B9C9D;
    }
    .el-row{
        font-weight:bold;
        margin-bottom: 15px;
    }
    .hint{
        color:#7ed321;
    }

}
.el-slider__marks-text{
  width: 20px !important;
}
</style>
<style lang="scss" scoped>

.saveStateContainer {
  margin-top: 10px;
  overflow: hidden;
  .saveState {
    float: right;
  }
}
</style>
